import React from 'react'
import LogoHeig from './LogoHeig'
import { useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import Div100vh from 'react-div-100vh'
import { title, subtitle } from './styles'

import Arrow from './Arrow'

const useStyles = makeStyles({
    section1: {
        background: 'url(/4-people.jpg)',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minHeight: 300,
        minWidth: 200,
    },
    logo: {
        position: 'absolute',
        top: 36,
        left: 0,
        width: 36,
    },
    biggerLogo: {
        top: 48,
        width: 48,
    },
    wrapper: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0,0,0,.2)',
        overflow: 'hidden',
        '&.md': {
            justifyContent: 'flex-end',
            paddingBottom: 64,
        },
        '&.strip': {
            paddingBottom: 0,
        },
    },
    content: {
        width: '100%',
        padding: '0 64px',
        '&.strip': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
        },
    },
    subtitle: {
        ...subtitle,
        fontSize: 36,
        '&.md': {
            fontSize: 48,
        },
    },
    title: {
        ...title,
        fontSize: 36,
        '&.md': {
            fontSize: 48,
        },
        borderTop: 'solid 6px white',
    },
    department: {
        fontSize: 21,
        fontFamily: 'DINPro',
        fontWeight: 'bold',
        margin: '24px 0 48px 0',
        '&.md': {
            fontSize: 24,
        },
    },
    buttons: {
        display: 'flex',
        flexDirection: 'column',
        '&.md': {
            flexDirection: 'row',
        },
    },
    button: {
        background: 'white',
        border: 'none',
        color: 'black',
        display: 'block',
        fontFamily: 'DINPro',
        fontSize: 24,
        fontWeight: 500,
        height: 'min-content',
        margin: '0 auto',
        padding: '12px 24px',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
        width: 'min-content',
        '&.md': {
            margin: 0,
            fontSize: 24,
        },
    },
    buttonHEIGVDWebsite: {
        marginTop: 16,
        '&.md': {
            marginLeft: 16,
        },
    },
    arrowWrapper: {
        width: '100%',
        background: 'red',
        height: 64,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    arrow: {
        width: 64,
    },
})

const scrollDown = e => {
    e.preventDefault()
    console.log('scroll')
    window.scrollTo({
        top: document.documentElement.clientHeight,
        behavior: 'smooth',
    })
}

export default () => {
    const styles = useStyles()
    const sm = useMediaQuery('(min-width: 376px)')
    const md = useMediaQuery('(min-width: 768px)')
    const strip = useMediaQuery('(max-height: 475px)')
    return (
        <Div100vh>
            <div className={clsx(styles.section1, md && 'md')}>
                <LogoHeig
                    className={clsx(styles.logo, sm && styles.biggerLogo)}
                />
                <div
                    className={clsx(
                        styles.wrapper,
                        md && 'md',
                        md && strip && 'strip'
                    )}
                >
                    <div
                        className={clsx(styles.content, md && strip && 'strip')}
                    >
                        <div>
                            <span className={clsx(styles.subtitle, md && 'md')}>
                                Talentueux.
                            </span>
                            <h1 className={clsx(styles.title, md && 'md')}>
                                Bachelor of Science HES-SO en Économie
                                d'entreprise
                            </h1>
                            <div
                                className={clsx(styles.department, md && 'md')}
                            >
                                Département HEG
                            </div>
                        </div>

                        <div className={clsx(styles.buttons, md && 'md')}>
                            <a
                                className={clsx(styles.button, md && 'md')}
                                href="https://inscription.heig-vd.ch/preload/bachelor--plein-temps--economie-d-entreprise--economie-d-entreprise"
                            >
                                Inscriptions
                            </a>
                            <a
                                className={clsx(
                                    styles.button,
                                    styles.buttonHEIGVDWebsite,
                                    md && 'md'
                                )}
                                href="https://heig-vd.ch/formations/bachelor/filieres/economie-entreprise"
                            >
                                Site HEIG-VD
                            </a>
                        </div>
                    </div>
                </div>
                <div className={styles.arrowWrapper}>
                    <Arrow className={styles.arrow} onClick={scrollDown} />
                </div>
            </div>
        </Div100vh>
    )
}
