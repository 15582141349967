import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useMediaQuery } from '@material-ui/core'
import clsx from 'clsx'
import { title, subtitle } from './styles'

const useStyles = makeStyles({
    section3: {
        background: 'white',
        padding: '32px 32px 0 32px',
        maxWidth: 1400,
        margin: '0 auto',
        color: 'black',
        '&.md': {
            padding: '64px 64px 0 64px',
        },
    },
    subtitle: {
        ...subtitle,
    },
    title: {
        ...title,
        borderTop: 'solid 5px black',
    },
    girls: {
        background: 'url(2-people.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        width: 'calc(100% + 64px)',
        height: 600,
        margin: '0 -32px 0 -32px',
        '&.md': {
            margin: '0 -64px 0 -64px',
            width: 'calc(100% + 128px)',
            height: 860,
        },
        '&.lg': {
            width: '100%',
            margin: 0,
        },
    },
    button: {
        background: 'white',
        border: 'solid 3px black',
        color: 'black',
        display: 'inline-block',
        fontFamily: 'DINPro',
        fontSize: 21,
        fontWeight: 'bold',
        margin: '48px 0 48px 0',
        padding: '8px',
        textDecoration: 'none',
    },
    list: {
        marginBottom: 32,
        padding: 0,

        '& li': {
            margin: 0,
            paddingLeft: 16,
            listStyleType: 'none',
            background: 'url(chevron.svg) no-repeat -7px 0px transparent',
        },
        '& a': {
            color: 'inherit',
            textDecoration: 'none',
        },
    },
    grid: {
        display: 'grid',
        gridGap: '32px 16px',
        gridTemplateColumns: '1fr 1fr',
    },
    email: {
        color: 'black',
    },
    phone: {
        color: 'black',
    },
})

const Content = ({ styles, md }) => (
    <div>
        <span className={styles.subtitle}>Enthousiaste.</span>
        <h2 className={styles.title}>Rejoignez-nous !</h2>
        <p>N’hésitez pas à nous contacter pour toute question :</p>
        <p>
            Véronique Fürst
            <br />
            Secrétaire Département HEG
            <br />
            Admissions
            <br />
        </p>
        <p>
            <a className={styles.phone} href="tel:0245577602">
                024 557 76 02
            </a>
            <br />
            <a className={styles.phone} href="tel:0245576252">
                024 557 62 52
            </a>
            <br />
            <a
                className={styles.email}
                href="mailto:veronique.furst@heig-vd.ch"
            >
                veronique.furst@heig-vd.ch
            </a>
        </p>
        <p>
            HEIG-VD
            <br />
            Département HEG
            <br />
            Avenue des Sports 20
            <br />
            Case postale 521
            <br />
            1401 Yverdon-les-Bains
        </p>
        <a
            className={clsx(styles.button, md && 'md')}
            href="https://heig-vd.ch/formations/bachelor/admission/conditions-admission/economiste"
            target="_blank"
            rel="noopener noreferrer"
        >
            Conditions d'admission
        </a>
        <h3>Documents utiles</h3>
        <ul className={styles.list}>
            <li>
                <a
                    href="/docs/191120-plan-etude-PT.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Plan d’étude à plein temps
                </a>
            </li>
            <li>
                <a
                    href="/docs/191120-plan-etude-EE.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Plan d’étude en emploi
                </a>
            </li>
            <li>
                <a
                    href="/docs/brochure-heg.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Brochure HEIG-VD
                </a>
            </li>
        </ul>
        <a
            className={clsx(styles.button, md && 'md')}
            href="https://heig-vd.ch/formations/bachelor/filieres/economie-entreprise"
            target="_blank"
            rel="noopener noreferrer"
        >
            Plus d'informations
        </a>
    </div>
)

const GirlsPicture = ({ styles, md, lg }) => (
    <div className={clsx(styles.girls, md && 'md', lg && 'lg')} />
)

export default () => {
    const styles = useStyles()

    const md = useMediaQuery('(min-width: 768px)')
    const lg = useMediaQuery('(min-width: 991px)')
    return (
        <div className={clsx(styles.section3, md && 'md')}>
            {lg ? (
                <div className={styles.grid}>
                    <Content styles={styles} md={md} />
                    <GirlsPicture styles={styles} md={md} lg={lg} />
                </div>
            ) : (
                <>
                    <Content styles={styles} md={md} />
                    <GirlsPicture styles={styles} md={md} />
                </>
            )}
        </div>
    )
}
