import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Page from './Page'

const useStyles = makeStyles({
    app: {},
})

export default () => {
    const styles = useStyles()
    return (
        <div className={styles.app}>
            <Page />
        </div>
    )
}
