import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'

const useStyles = makeStyles({
    page: {},
})

export default () => {
    const styles = useStyles()
    return (
        <div className={styles.page}>
            <Section1 />
            <Section2 />
            <Section3 />
        </div>
    )
}
