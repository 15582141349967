import React from 'react'

export default ({ className, onClick }) => {
    return (
        <svg
            onClick={onClick}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            <path
                d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
                fill="#ffffff"
            />
        </svg>
    )
}
