import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useMediaQuery } from '@material-ui/core'
import clsx from 'clsx'
import { title, subtitle } from './styles'

const useStyles = makeStyles({
    wrapper: {
        background: '#ff0000',
    },
    section2: {
        background: '#ff0000',
        padding: '64px 32px 64px 32px',
        color: 'white',
        maxWidth: 1400,
        margin: '0 auto',
        '&.md': {
            padding: 64,
        },
    },
    subtitle: {
        ...subtitle,
    },
    title: {
        ...title,
    },
    chapeau: {
        margin: '32px 0 32px 0',
        fontSize: 21,
        fontFamily: 'DINPro',
        fontWeight: 500,
        '&.md': {
            fontSize: 24,
        },
    },
    fact: {
        fontFamily: 'DINPro',
        fontWeight: 500,
        fontSize: 32,
        marginTop: 48,
        marginBottom: 96,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&.md': {
            fontSize: 36,
        },
        '&.lg': {
            gridColumn: '1 / span 2',
        },
    },
    factWrapper: {
        paddingTop: 32,
        borderTop: 'solid 5px white',
        '&.lg': {
            width: '40%',
        },
    },
    factRateOFS: {
        fontSize: 16,
        textAlign: 'right',
        marginTop: 16,
    },
    list: {
        margin: '0 0 32px 0',
        paddingLeft: 21,
        lineHeight: 1.5,
    },
    values: {
        fontFamily: 'DINPro',
        marginBottom: 16,
    },
    grid: {
        display: 'grid',
        gridGap: '32px 64px',
        gridTemplateColumns: '2fr 3fr',
    },
    videoWrapper: {
        height: 0,
        overflow: 'hidden',
        position: 'relative',
        paddingBottom: '56.25%',
        paddingTop: 30,
        margin: '0 -32px 64px -32px',
        '&.md': {
            margin: '0 0 64px 0',
        },
        '& iframe': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
        },
    },
})

const Ambitious = ({ styles, md }) => (
    <div>
        <span className={clsx(styles.subtitle, md && 'md')}>Ambitieuse.</span>
        <h2 className={clsx(styles.title, md && 'md')}>
            Une formation professionnalisante de haut niveau
        </h2>
        <div className={clsx(styles.chapeau, md && 'md')}>
            Alliant connaissances théoriques de pointe et exigences de la
            pratique professionnelle, la formation de Bachelor HES en Économie
            d’entreprise permet d’acquérir des compétences adaptées aux besoins
            du marché du travail et de disposer d’excellentes perspectives
            d’avenir.
        </div>
        <div className={styles.values}>Les plus</div>
        <ul className={styles.list}>
            <li>
                Possibilité de suivre les études à temps plein, en emploi ou à
                temps partiel
            </li>
            <li>
                Formation adaptée aux besoins actuels et futurs des entreprises
            </li>
            <li>
                Professeur·e·s impliqué·e·s dans le tissu économique avec une
                très bonne maîtrise des enjeux et réalités du terrain
            </li>
            <li>
                Enseignement de proximité et professeur·e·s disponibles grâce à
                des classes composées de petits effectifs.
            </li>
        </ul>
    </div>
)

const Qualified = ({ styles, md }) => (
    <div>
        <span className={clsx(styles.subtitle, md && 'md')}>Compétente.</span>
        <h2 className={clsx(styles.title, md && 'md')}>
            De multiples débouchés professionnels
        </h2>
        <div className={clsx(styles.chapeau, md && 'md')}>
            Le Bachelor en Économie d’entreprise délivré par la HEIG-VD jouit
            d’une excellente reconnaissance au sein du tissu économique. Les
            compétences développées durant les études sont très recherchées sur
            le marché du travail et permettent aux étudiant-e-s d’occuper une
            large variété de fonctions à responsabilité au sein des différents
            secteurs de l’économie.
        </div>
        <div className={styles.values}>Domaines d’enseignement</div>
        <ul className={styles.list}>
            <li>Gestion d’entreprise / Management / Ressources Humaines</li>
            <li>Finance / Contrôle de gestion</li>
            <li>Comptabilité / Révision</li>
            <li>Management de projet</li>
            <li>Développement durable</li>
            <li>Start-up / Entrepreneuriat</li>
            <li>Communication & Marketing / Études de marché</li>
            <li>Supply chain</li>
        </ul>
    </div>
)

const Video = src => ({ styles, md }) => (
    <div className={clsx(styles.videoWrapper, md && 'md')}>
        <iframe
            title={src}
            src={src}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        ></iframe>
    </div>
)

const StudentsVideo = Video('https://www.youtube.com/embed/0qIEv2bxJ9Y')

const TeachersVideo = Video('https://www.youtube.com/embed/moP55Q525AQ')

const FactRate = ({ styles, md, lg }) => (
    <div className={clsx(styles.fact, md && 'md', lg && 'lg', styles.factRate)}>
        <div className={clsx(styles.factWrapper, lg && 'lg')}>
            Le taux d’employabilité des diplômé·e·s une année après l’obtention
            de leur Bachelor HES est de plus de 96%.
            <div className={styles.factRateOFS}>
                Office fédéral de la statistique, 2016
            </div>
        </div>
    </div>
)

const Fact99 = ({ styles, md, lg }) => (
    <div className={clsx(styles.fact, md && 'md', lg && 'lg', styles.fact99)}>
        <div className={clsx(styles.factWrapper, lg && 'lg')}>
            99% des travaux de Bachelor sont confiés par des entreprises
        </div>
    </div>
)

export default () => {
    const styles = useStyles()
    const md = useMediaQuery('(min-width: 768px)')
    const lg = useMediaQuery('(min-width: 991px)')
    return (
        <div className={styles.wrapper}>
            <div className={clsx(styles.section2, md && 'md')}>
                {lg ? (
                    <>
                        <div className={styles.grid}>
                            <Qualified styles={styles} md={md} />
                            <StudentsVideo styles={styles} md={md} />
                            <FactRate styles={styles} md={md} lg={lg} />
                            <Ambitious styles={styles} md={md} />
                            <TeachersVideo styles={styles} md={md} />
                            <Fact99 styles={styles} md={md} lg={lg} />
                        </div>
                    </>
                ) : (
                    <>
                        <Qualified styles={styles} md={md} />
                        <StudentsVideo styles={styles} md={md} />
                        <FactRate styles={styles} md={md} lg={lg} />
                        <Ambitious styles={styles} md={md} />
                        <TeachersVideo styles={styles} md={md} />
                        <Fact99 styles={styles} md={md} lg={lg} />
                    </>
                )}
            </div>
        </div>
    )
}
