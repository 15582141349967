import React from 'react'

export default ({ className }) => (
    <div className={className}>
        <a href="https://heig-vd.ch">
            <svg
                width="100%"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 39.7 82.2"
                style={{ enableBackground: 'new 0 0 39.7 82.2' }}
                xmlSpace="preserve"
            >
                <rect
                    x={0}
                    style={{ fill: '#FF0000' }}
                    width="39.7"
                    height="82.2"
                />
                <path
                    style={{ fill: '#FFFFFF' }}
                    d="M30.6 66.9H22c-1.9 0-3.9.3-3.9 3.2 0 1.1.3 1.7.7 2.2.8 1 2.2 1.2 3.1 1.2h8.6v1.4H12.7v-1.4h6.4c-.7-.1-2.2-1.3-2.2-3.6 0-1.8.7-3 1.5-3.6.9-.6 2.1-.7 3.6-.7h8.6v1.3zm-6.3-5h.5c1.2-.1 4.7-.4 4.7-4.7 0-2.6-1.9-4-1.9-4l1.7.2s1.4 1.3 1.4 3.8c0 5.7-4.7 6.1-6.8 6.1-2.2 0-7-.6-7.1-5.4 0-4.9 5.3-5.2 6.7-5.2h.7v9.2zm-6.2-4c-.1 3.9 4.7 3.9 5 3.9v-7.6s-5-.1-5 3.7zm6.8-23.2h-1.4V29h1.4v5.7zm5.9-22.4c0 3.6-3.2 5.1-7 5.1s-6.9-2-6.8-5.2c0-2.3 1.4-3.3 1.9-3.4h-6.2V7.3h13.1c1.9 0 5 1.1 5 5zm-8.7-3.6c-.7 0-2.2.1-3.1.9-.6.5-1 1.4-1 2.5 0 2.5 2.6 3.8 5.7 3.8s5.7-1.1 5.7-3.8c0-2.2-1.9-3.4-3.5-3.4h-3.8zm-4.9 10.7s11.6 3.6 11.7 3.7c.2.1.2.1 0 .2-.1 0-11.7 3.7-11.7 3.7v1.4l12.5-4c.7-.3 1-.7 1-1.2 0-.6-.3-.9-1-1.2l-12.5-4v1.4zm13.4 31H17.2V49h13.4v1.4zm-15.6 0h-1.5V49H15v1.4zm13-9.2c0 1.3.3 4-1 4-1.2 0-1-1.9-1-3.8 0-3-1.7-4.7-4.5-4.7-1.5 0-2.6.5-3.5 1.4v-1.6h-1.2v5.1c0 2.1 1.1 4.4 4.6 4.4 2.3 0 3.5-1.5 3.6-2.1 0 .6.3 2.6 2.1 2.6 1 0 1.5-.6 1.7-1.7.2.6 1.2 1.8 2.8 1.8 1.9 0 3.4-1.7 3.4-5.2 0-2.9-1.2-5-3.7-5.1-2-.1-3.3 1.4-3.3 4.9zm-6.5 3.5c-2 0-3.4-1-3.4-3.3 0-2.5 1.8-3.4 3.4-3.4 2.3 0 3.4 1.4 3.4 3.5 0 1.8-1.4 3.2-3.4 3.2zm12.6-3.4c0 2.5-1 3.9-2.5 3.9-1.1 0-2.1-1-2.3-1.7v-2.3c0-2.9 1.1-3.7 2.3-3.7 1.6.1 2.5 2 2.5 3.8z"
                />
            </svg>
        </a>
    </div>
)
