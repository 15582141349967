export const subtitle = {
    fontFamily: 'DINPro',
    fontWeight: 'bold',
    fontSize: 24,
    display: 'block',
    '&.md': {
        fontSize: 32,
    },
}
export const title = {
    fontFamily: 'DINPro',
    fontWeight: 'bold',
    margin: 0,
    marginTop: 8,
    paddingTop: 8,
    fontSize: 24,
    borderTop: 'solid 4px white',
    display: 'inline-block',
    '&.md': {
        fontSize: 32,
    },
}
